<template>
    <div>
        <!-- ======= Header ======= -->
        <header id="header" class="fixed-top">
            <div class="container d-flex align-items-center">

                <a href="" class="logo me-auto"><img src="assets/img/favicon-griyabelajar.png" alt=""
                        class="img-fluid"></a>
                <h1 class="logo me-auto logo-text"><a href="">GriyaBelajar</a></h1>
                <!-- Uncomment below if you prefer to use an image logo -->

                <nav id="navbar" class="navbar">
                    <ul>
                        <li><a class="nav-link scrollto active" href="#hero">Beranda</a></li>
                        <li><a class="nav-link scrollto" href="#privilage">Keunggulan</a></li>
                        <li><a class="nav-link scrollto" href="#program">Program</a></li>
                        <li><a class="nav-link scrollto" href="#package">Paket</a></li>
                        <li><a class="nav-link  scrollto" href="#testimonial">Testimoni</a></li>
                        <li><a class="nav-link  scrollto" href="#faq">FAQ</a></li>
                        <li><a class="text-center getstarted scrollto" :href="frontend_url">Masuk</a></li>
                    </ul>
                    <i class="bi bi-list mobile-nav-toggle"></i>
                </nav><!-- .navbar -->

            </div>
        </header><!-- End Header -->

        <!-- ======= Hero Section ======= -->
        <section id="hero" class="d-flex align-items-center">
            <div class="container">                
                <div class="row">
                    <div class="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
                        data-aos="fade-up" data-aos-delay="200">
                        <h1>Perjalanan Kamu Menjadi Sukses Dimulai dari Sini!</h1>
                        <h2>Belajar setiap hari belum tentu dapat menguasai materi. Coba terapkan konsep belajar Learn,
                            Do & Teach
                            bersama kami!</h2>
                        <div class="d-flex justify-content-center justify-content-lg-start">
                            <a :href="frontend_url + '#/signup'" class="btn-get-started scrollto">Daftar Sekarang</a>

                            <a v-if="web_profile.url_video_about" :href="web_profile.url_video_about" class="glightbox btn-watch-video"><i
                                    class="bi bi-play-circle"></i><span>Tentang Kami</span></a>
                        </div>
                    </div>
                    <div class="col-lg-6 order-1 order-lg-2 hero-img" data-aos="zoom-in" data-aos-delay="200">
                        <img src="assets/img/hero-img.png" class="img-fluid animated" alt="">
                    </div>
                </div>
            </div>
        </section><!-- End Hero -->

        <main id="main">
            <section v-if="!fetchBanner && banner_data.length > 0" id="promotion" class="about section-bg" style="background-image: url('assets/img/grey-bg.png'); background-size: cover;">
                <div class="container" data-aos="fade-up">

                    <div class="section-title">
                        <h2>Promo Terbaru</h2>
                    </div>
                    <div class="row">
                        <div class="col-md-8 col-sm-12 mx-auto hero-img" data-aos="zoom-in" data-aos-delay="200">
                            <div class="glide">
                                <div class="glide__track" data-glide-el="track">
                                    <ul class="glide__slides promtion-shadow">
                                        <li v-for="(item, index) in banner_data" :key="index" class="glide__slide promotion-banner">
                                            <router-link :to="{name : 'promotion', params : {slug : item.code}}" target="blank_">
                                                <img v-if="!item.cover" style="width:100%" src="assets/img/default-promo.png">
                                                <img v-if="item.cover" style="width:100%" :src="item.cover">
                                            </router-link>
                                        </li>
                                    </ul>
                                </div>

                                <div class="glide__bullets" data-glide-el="controls">
                                    <button v-for="(item, index) in banner_data" :key="index" class="glide__bullet" :data-glide-dir="index++"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="privilage" class="about">
                <div class="container" data-aos="fade-up">

                    <div class="section-title">
                        <h2>KEUNGGULAN<br />“GRIYA BELAJAR”</h2>
                    </div>

                    <div class="row content">
                        <div class="col-lg-12">
                            <p>
                                Selamat, anda berada di website atau Platform yang tepat dalam belajar. Sebagaimana
                                Program-Program yang
                                kami sampaikan. Keunggulan dari Kami Griya Belajar. Diantaranya :
                            </p>
                            <ul>
                                <li><i class="ri-check-double-line"></i> Hybrid System Learning (HSL).</li>
                                <li><i class="ri-check-double-line"></i> Low Cost</li>
                                <li><i class="ri-check-double-line"></i> Try Out Online</li>
                                <li><i class="ri-check-double-line"></i> Terdaftar Resmi Oleh pemerintah</li>
                                <li><i class="ri-check-double-line"></i> Jadwal Fleksibel</li>
                                <li><i class="ri-check-double-line"></i> Daily Exercises</li>
                                <li><i class="ri-check-double-line"></i> Bergaransi 100%</li>
                                <li><i class="ri-check-double-line"></i> Materi dan soal sesuai FR ter update</li>
                            </ul>
                        </div>
                    </div>

                </div>
            </section>
            <!-- ======= End Privilage Us Section ======= -->

            <!-- ======= Program Us Section ======= -->
            <section id="program" class="why-us section-bg">
                <div class="container-fluid" data-aos="fade-up">

                    <div class="row">

                        <div
                            class="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">

                            <div class="content">
                                <h3>Program <strong>GRIYA BELAJAR</strong></h3>
                                <p>
                                    Griya belajar, adalah salah satu program pengembangan Lembaga NHSC yang sudah
                                    memiliki trust di
                                    masyarakat dengan dibuktikan oleh beberapa testimoni alumni kami yang selalu puas
                                    dengan program kami.
                                </p>
                                <p>
                                    Adapun program-Program yang kami tawarkan. Diantaranya :
                                </p>
                            </div>

                            <div class="accordion-list">
                                <ul>
                                    <li>
                                        <a data-bs-toggle="collapse" class="collapse"
                                            data-bs-target="#accordion-list-1"><span>01</span>Program UTBK<i
                                                class="bx bx-chevron-down icon-show"></i><i
                                                class="bx bx-chevron-up icon-close"></i></a>
                                        <div id="accordion-list-1" class="collapse show"
                                            data-bs-parent=".accordion-list">
                                            <p>
                                                Program UTBK adalah program kami yang ditujukan untuk alumni atau mereka
                                                yang masih duduk dibangku
                                                SLTA untuk bisa masuk ke PTN Favorite. Soal-soal yang kami sajikan
                                                tentunya soal soal yang sesuai
                                                dengan FR terbaru dengan Latihan soal harian beserta puluhan Try Out
                                                online.
                                            </p>
                                        </div>
                                    </li>

                                    <li>
                                        <a data-bs-toggle="collapse" data-bs-target="#accordion-list-2"
                                            class="collapsed"><span>02</span>Program Kedinasan<i
                                                class="bx bx-chevron-down icon-show"></i><i
                                                class="bx bx-chevron-up icon-close"></i></a>
                                        <div id="accordion-list-2" class="collapse" data-bs-parent=".accordion-list">
                                            <p>
                                                Program Kedinasan adalah program kami yang ditujukan untuk alumni atau
                                                mereka yang masih duduk dibangku SLTA untuk bisa masuk ke PTN Favorite.
                                                Soal-soal yang kami sajikan tentunya soal soal yang sesuai dengan FR
                                                terbaru dengan Latihan soal harian beserta puluhan Try Out online yang
                                                tersedia dalam satu platform kami.
                                            </p>
                                        </div>
                                    </li>

                                    <li>
                                        <a data-bs-toggle="collapse" data-bs-target="#accordion-list-3"
                                            class="collapsed"><span>03</span>Program CPNS <i
                                                class="bx bx-chevron-down icon-show"></i><i
                                                class="bx bx-chevron-up icon-close"></i></a>
                                        <div id="accordion-list-3" class="collapse" data-bs-parent=".accordion-list">
                                            <p>
                                                Program CPNS adalah program kami yang ditujukan untuk mereka yang ingin
                                                menjadi ASN sesuai formasinya masing-masing. Soal-soal yang kami sajikan
                                                tentunya soal soal yang sesuai dengan FR terbaru dengan Latihan soal
                                                harian beserta puluhan Try Out online.
                                            </p>
                                        </div>
                                    </li>

                                    <li>
                                        <a data-bs-toggle="collapse" data-bs-target="#accordion-list-4"
                                            class="collapsed"><span>04</span>Program PPPK <i
                                                class="bx bx-chevron-down icon-show"></i><i
                                                class="bx bx-chevron-up icon-close"></i></a>
                                        <div id="accordion-list-4" class="collapse" data-bs-parent=".accordion-list">
                                            <p>
                                                Program PPPK adalah program kami yang ditujukan untuk mereka yang ingin
                                                menjadi ASN dengan jalur PPPK sesuai formasinya masing-masing. Soal-soal
                                                yang kami sajikan tentunya soal soal yang sesuai dengan FR terbaru
                                                dengan Latihan soal harian beserta puluhan Try Out online
                                            </p>
                                        </div>
                                    </li>

                                    <li>
                                        <a data-bs-toggle="collapse" data-bs-target="#accordion-list-5"
                                            class="collapsed"><span>05</span>Program SKB <i
                                                class="bx bx-chevron-down icon-show"></i><i
                                                class="bx bx-chevron-up icon-close"></i></a>
                                        <div id="accordion-list-5" class="collapse" data-bs-parent=".accordion-list">
                                            <p>
                                                Program SKB, adalah program kami yang menjadi lanjutan setelah tahap SKD
                                                tuntas. Dalam program SKB (Seleksi Kompetensi Bidang), peserta di
                                                berikan kebebasan memilih Program SKB yang kami tawarkan sesuai formasi
                                                yang dipilihnya masing-masing.
                                            </p>
                                        </div>
                                    </li>

                                    <li>
                                        <a data-bs-toggle="collapse" data-bs-target="#accordion-list-6"
                                            class="collapsed"><span>06</span>Program English Academy<i
                                                class="bx bx-chevron-down icon-show"></i><i
                                                class="bx bx-chevron-up icon-close"></i></a>
                                        <div id="accordion-list-6" class="collapse" data-bs-parent=".accordion-list">
                                            <p>
                                                English Academy adalah program yang kami tawarkan dengan metode deep
                                                Listening, Speaking dan Habitual Action. Peserta akan diberikan
                                                bimbingan dalam prakteknya
                                            </p>
                                        </div>
                                    </li>

                                    <li>
                                        <a data-bs-toggle="collapse" data-bs-target="#accordion-list-7"
                                            class="collapsed"><span>07</span>Training SDM<i
                                                class="bx bx-chevron-down icon-show"></i><i
                                                class="bx bx-chevron-up icon-close"></i></a>
                                        <div id="accordion-list-7" class="collapse" data-bs-parent=".accordion-list">
                                            <p>
                                                Training SDM adalah salah satu program kami untuk kalian yang ingin
                                                mengupgrade diri. Ada beberapa program yang bisa kalian semua nikmati
                                                untuk berbagai kalangan di dalam platform kami.
                                            </p>
                                        </div>
                                    </li>

                                </ul>
                            </div>

                        </div>

                        <div class="col-lg-5 align-items-stretch order-1 order-lg-2 img"
                            style='background-image: url("assets/img/why-us.png");' data-aos="zoom-in"
                            data-aos-delay="150">&nbsp;</div>
                    </div>

                </div>
            </section>
            <!-- ======= End Program Us Section ======= -->

            <!-- ======= Pakacge Section ======= -->
            
            <section id="package" class="pricing">
                <div v-if="cpns_data.length > 0" class="container" data-aos="fade-up">
                    <div class="section-title">
                        <h2>Paket SKD CPNS</h2>
                        <p>Terus latihan dan Uji kemampuanmu dengan TryOut dan soal - soal UTBK berkualitas sesuai
                            standar yang sebenarnya
                            dengan harga terjangkau.</p>
                    </div>

                    <div class="row">
                        <div v-for="(item, index) in cpns_data" :key="index" class="col-lg-4" data-aos="fade-up" data-aos-delay="100">
                            <div class="box featured text-center">
                                <h3>Paket {{item.name}}</h3>
                                <h4 v-if="item.type_package == 'free'"><sup>Rp</sup> <del>0</del> Gratis !</h4>
                                <h4 v-if="item.type_package != 'free'">
                                    {{item.text_price}}<br/>
                                    <small v-if="item.text_price_out" style="font-size:25px !important"><del>{{item.text_price_out}}</del></small><br/>
                                </h4>
                                <ul>
                                    <li v-for="(detail, indexDetail) in item.description_other" :key="indexDetail"><i class="bx bx-check"></i> {{detail}}</li>
                                </ul>
                                <a :href="frontend_url + '#/signup'" class="buy-btn">Daftar Sekarang</a>
                            </div>
                        </div>
                    </div>

                </div>

                <div v-if="kedinasan_data.length > 0" class="container" style="margin-top: 100px;" data-aos="fade-up">
                    <div style="border-top: 3px dotted #df7b11;"></div>
                </div>

                <div v-if="kedinasan_data.length > 0" style="margin-top: 50px;" class="container" data-aos="fade-up">
                    <div class="section-title">
                        <h2>Paket SKD Kedinasan</h2>
                        <p>Terus latihan dan Uji kemampuanmu dengan TryOut dan soal - soal UTBK berkualitas sesuai
                            standar yang sebenarnya
                            dengan harga terjangkau.</p>
                    </div>

                    <div class="row">
                        <div v-for="(item, index) in kedinasan_data" :key="index" class="col-lg-4" data-aos="fade-up" data-aos-delay="100">
                            <div class="box featured text-center">
                                <h3>Paket {{item.name}}</h3>
                                <h4 v-if="item.type_package == 'free'"><sup>Rp</sup> <del>0</del> Gratis !</h4>
                                <h4 v-if="item.type_package != 'free'">
                                    {{item.text_price}}<br/>
                                    <small v-if="item.text_price_out" style="font-size:25px !important"><del>{{item.text_price_out}}</del></small><br/>
                                </h4>
                                <ul>
                                    <li v-for="(detail, indexDetail) in item.description_other" :key="indexDetail"><i class="bx bx-check"></i> {{detail}}</li>
                                </ul>
                                <a :href="frontend_url + '#/signup'" class="buy-btn">Daftar Sekarang</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="utbk_data.length > 0" class="container" style="margin-top: 100px;" data-aos="fade-up">
                    <div style="border-top: 3px dotted #df7b11;"></div>
                </div>

                <div v-if="utbk_data.length > 0" style="margin-top: 50px;" class="container" data-aos="fade-up">
                    <div class="section-title">
                        <h2>Paket UTBK</h2>
                        <p>Terus latihan dan Uji kemampuanmu dengan TryOut dan soal - soal UTBK berkualitas sesuai
                            standar yang sebenarnya
                            dengan harga terjangkau.</p>
                    </div>

                    <div class="row">
                        <div v-for="(item, index) in utbk_data" :key="index" class="col-lg-4" data-aos="fade-up" data-aos-delay="100">
                            <div class="box featured text-center">
                                <h3>Paket {{item.name}}</h3>
                                <h4 v-if="item.type_package == 'free'"><sup>Rp</sup> <del>0</del> Gratis !</h4>
                                <h4 v-if="item.type_package != 'free'">
                                    {{item.text_price}}<br/>
                                    <small v-if="item.text_price_out" style="font-size:25px !important"><del>{{item.text_price_out}}</del></small><br/>
                                </h4>
                                <ul>
                                    <li v-for="(detail, indexDetail) in item.description_other" :key="indexDetail"><i class="bx bx-check"></i> {{detail}}</li>
                                </ul>
                                <a :href="frontend_url + '#/signup'" class="buy-btn">Daftar Sekarang</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="pppk_data.length > 0" class="container" style="margin-top: 100px;" data-aos="fade-up">
                    <div style="border-top: 3px dotted #df7b11;"></div>
                </div>

                <div v-if="pppk_data.length > 0" style="margin-top: 50px;" class="container" data-aos="fade-up">
                    <div class="section-title">
                        <h2>Paket PPPK</h2>
                        <p>Terus latihan dan Uji kemampuanmu dengan TryOut dan soal - soal pppk berkualitas sesuai
                            standar yang sebenarnya
                            dengan harga terjangkau.</p>
                    </div>

                    <div class="row">
                        <div v-for="(item, index) in pppk_data" :key="index" class="col-lg-4" data-aos="fade-up" data-aos-delay="100">
                            <div class="box featured text-center">
                                <h3>{{item.name}}</h3>
                                <h4 v-if="item.type_package == 'free'"><sup>Rp</sup> <del>0</del> Gratis !</h4>
                                <h4 v-if="item.type_package != 'free'">
                                    {{item.text_price}}<br/>
                                    <small v-if="item.text_price_out" style="font-size:25px !important"><del>{{item.text_price_out}}</del></small><br/>
                                </h4>
                                <ul>
                                    <li v-for="(detail, indexDetail) in item.description_other" :key="indexDetail"><i class="bx bx-check"></i> {{detail}}</li>
                                </ul>
                                <a :href="frontend_url + '#/signup'" class="buy-btn">Daftar Sekarang</a>
                            </div>
                        </div>
                    </div>

                    <div v-if="pppk_data.length >= 3" class="section-title" style="margin-top:25px">
                        <a :href="frontend_url + '#/member/paket/pppk'" class="buy-btn">Lihat Formasi PPPK Selengkapnya</a>
                    </div>
                </div>
            </section>
            <!-- End Pakacge Section -->

            <!-- ======= Testimonial Section ======= -->
            <section id="testimonial" class="team section-bg">
                <div class="container" data-aos="fade-up">
                    <div class="section-title">
                        <h2>Apa kata mereka tentang kita ?</h2>
                    </div>

                    <div class="row feature-slider testimoni-slider">
                        <div class="col-lg-6" v-for="(item, index) in listTestimoni" :key="index">
                            <div class="member d-flex align-items-start text-center" data-aos="zoom-in"
                                data-aos-delay="200">
                                <div class="member-info">
                                    <div class="pic"><img :src="item.image" class="img-fluid"
                                            alt=""></div>
                                    <h4 class="mt-3">{{item.name}}</h4>
                                    <span>{{item.position}} - {{item.description}}</span>
                                    <p>{{item.contents}}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <!-- ======= End Team Section ======= -->

            <!-- ======= Cta Section ======= -->
            <section id="cta" class="cta">
                <div class="container" data-aos="zoom-in">

                    <div class="row">
                        <div class="col-lg-9 text-center text-lg-start">
                            <h3>Mari Bergabung dan menjadi bagian dari kami!</h3>
                            <p>Yuk mulai belajar hari ini dan bersiap untuk bersaing dengan jutaan peserta lainnya!</p>
                        </div>
                        <div class="col-lg-3 cta-btn-container text-center">
                            <a class="cta-btn align-middle" :href="frontend_url + '#/signup'">Daftar Sekarang</a>
                        </div>
                    </div>

                </div>
            </section>
            <!-- End Cta Section -->

            <!-- ======= Frequently Asked Questions Section ======= -->
            <section id="faq" class="faq section-bg">
                <div class="container" data-aos="fade-up">

                    <div class="section-title">
                        <h2>Frequently Asked Questions (FAQ)</h2>
                        <p>Pertanyaan seputar tentang kami</p>
                    </div>

                    <div class="faq-list">
                        <ul>
                            <li v-for="(item, index) in listFaq" :key="index" data-aos="fade-up" data-aos-delay="100">
                                <i class="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse"
                                    class="collapse" :data-bs-target="'#faq-list-'+item.id">{{item.title}} <i
                                        class="bx bx-chevron-down icon-show"></i><i
                                        class="bx bx-chevron-up icon-close"></i></a>
                                    <div :id="'faq-list-'+item.id" class="collapse" data-bs-parent=".faq-list">
                                        <p>
                                            {{item.contents}}
                                        </p>
                                    </div>
                            </li>
                        </ul>
                    </div>

                </div>
            </section><!-- End Frequently Asked Questions Section -->

        </main><!-- End #main -->

        <!-- ======= Footer ======= -->
        <footer id="footer">
            <div class="footer-top">
                <div class="container">
                    <div class="row">

                        <div class="col-lg-4 col-md-6 footer-contact">
                            <div class="text-center">
                                <a href="#hero" class="logo me-auto"><img style="width:70%" src="assets/img/logo.png"
                                        alt="" class="img-fluid"></a>
                            </div>
                            <p class="mt-3">
                                {{web_profile.address}}
                                <br><br />
                                <strong>Nomor Hanphone : </strong>{{web_profile.phone}}<br />
                                <strong>Whatsapp : </strong> <a
                                    :href="'https://web.whatsapp.com/send?phone='+web_profile.whatsapp"
                                    target="blank">+{{web_profile.whatsapp}} (WhatsApp)</a><br />
                                <strong>Alamat Email : </strong> <a :href="'mailto:'+web_profile.email"
                                    target="blank">{{web_profile.email}}</a><br />
                            </p>
                        </div>

                        <div class="col-lg-4 col-md-6 footer-links">
                            <h4>Tentang Kami</h4>
                            <p>{{web_profile.about}}</p>
                        </div>

                        <div class="col-lg-4 col-md-6 footer-links">
                            <h4>Sosial Media</h4>
                            <p>Yuk, Join dengan sosial media kami dan dapatkan informasi-informasi menarik lainya dari
                                sosial media kami.</p>
                            <div class="social-links mt-3">
                                <a v-if="web_profile.url_youtube" :href="web_profile.url_youtube"
                                    target="blank" class="youtube"><i class="bx bxl-youtube"></i></a>
                                <a v-if="web_profile.url_facebook" :href="web_profile.url_facebook"
                                    target="blank" class="facebook"><i class="bx bxl-facebook"></i></a>
                                <a v-if="web_profile.url_instagram" :href="web_profile.url_instagram"
                                    target="blank" class="instagram"><i class="bx bxl-instagram"></i></a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="footer-bottom clearfix">
                <div class="container">
                    <div class="copyright">
                        &copy; Copyright <strong><span>GriyaBelajar</span></strong>. All Rights Reserved
                    </div>
                    <div class="credits">
                        Dibuat dengan <span style="color: #fff;">&#10084;</span> oleh GriyaBelajar.
                    </div>
                </div>
            </div>
        </footer><!-- End Footer -->

    </div>
</template>

<script>
    export default {
        name: 'Home',
        metaInfo: {
            title: 'Selamat datang',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                fetchTestimoni  : false,
                listTestimoni   : [],
                fetchFaq        : false,
                listFaq         : [],
                fetchProfile    : false,
                web_profile     : [],
                fetchCpns       : false,
                cpns_data       : [],
                fetchKedinasan  : false,
                kedinasan_data  : [],
                fetchUtbk       : false,
                utbk_data       : [],
                fetchPppk       : false,
                pppk_data       : [],
                fetchBanner       : false,
                banner_data       : [],
                frontend_url    : process.env.VUE_APP_FRONTEND,
                backend_url     : process.env.VUE_APP_APIHOST
            }
        },
        mounted() {
            let externalScript = document.createElement('script')
            externalScript.setAttribute('src', 'assets/js/main.js')
            document.head.appendChild(externalScript)
        },
        created() {
            this.getBanner();
            this.getTestimoni();
            this.getFaq();
            this.getProfile();
            this.getCpns();
            this.getKedinasan();
            this.getUtbk();
            this.getPppk();
        },
        methods: {
            getGlide: function () {
                let externalScript = document.createElement('script')
                externalScript.setAttribute('src', 'assets/js/custom-glide.js')
                document.head.appendChild(externalScript)
            },
            getTestimoni: function() {
                let uri         = this.backend_url+'website/testimoni/list';

                this.fetchTestimoni  = true;                
                this.$http.get(uri).then(res => {
                    this.fetchTestimoni  = false;                
                    this.listTestimoni   = res.data.data;

                    let testimoni = document.createElement('script')
                    testimoni.setAttribute('src', 'assets/js/testimoni.js')
                    document.head.appendChild(testimoni)
                });
            },
            getFaq: function() {
                let uri         = this.backend_url+'website/faq/list';

                this.fetchFaq  = true;                
                this.$http.get(uri).then(res => {
                    this.fetchFaq  = false;                
                    this.listFaq   = res.data.data;
                });
            },
            getProfile: function() {
                let uri         = this.backend_url+'website/profile';

                this.fetchProfile  = true;                
                this.$http.get(uri).then(res => {
                    this.fetchProfile  = false;                
                    this.web_profile   = res.data.data;
                });
            },
            getCpns: function() {
                let uri         = this.backend_url+'website/package?type=cpns';

                this.fetchCpns  = true;                
                this.$http.get(uri).then(res => {
                    this.fetchCpns  = false;                
                    this.cpns_data   = res.data.data;
                });
            },
            getKedinasan: function() {
                let uri         = this.backend_url+'website/package?type=kedinasan';

                this.fetchKedinasan  = true;                
                this.$http.get(uri).then(res => {
                    this.fetchKedinasan  = false;                
                    this.kedinasan_data   = res.data.data;
                });
            },
            getUtbk: function() {
                let uri         = this.backend_url+'website/package?type=utbk';

                this.fetchUtbk  = true;                
                this.$http.get(uri).then(res => {
                    this.fetchUtbk  = false;                
                    this.utbk_data   = res.data.data;
                });
            },
            getPppk: function() {
                let uri         = this.backend_url+'website/package?type=pppk&limit=3';

                this.fetchPppk  = true;                
                this.$http.get(uri).then(res => {
                    this.fetchPppk  = false;                
                    this.pppk_data   = res.data.data;
                });
            },
            getBanner: function() {
                let uri         = this.backend_url+'website/banner/list';

                this.fetchBanner  = true;                
                this.$http.get(uri).then(res => {
                    this.fetchBanner  = false;                
                    this.banner_data   = res.data.data;

                    if(this.banner_data.length > 0) {
                        this.getGlide();
                    }
                });
            },
        }
    }
</script>